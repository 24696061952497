<template>
  <app-layout class="bg-white">
    <app-loading-page
      :text="$t('general.submitting')"
      :loading="submitLoading"
      class="w-40 h-20 m-auto rounded-md shadow-md bg-transparent"
    />
    <!-- Approve Business-->
    <app-modal :show="show_modal_approve" @close="show_modal_approve = false">
      <template v-slot:title>{{ $t("business.modal.approve_title") }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("business.modal.approve_description") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button
          @onClick="updateStatusBusiness(BUSINESS_STATUS.APPROVED, null)"
          @click="show_modal_approve = false"
          :showf70Icon="false"
          :primary="false"
          type="button"
          class="
            sm:ml-3 sm:w-auto
            bg-approve
            hover:bg-success
            text-white
            outline-none
          "
        >
          {{ $t("business.modal.approve_button") }}
        </app-button>
      </template>
    </app-modal>

    <!-- Amend Business-->
    <app-modal :show="show_modal_amend" @close="show_modal_amend = false">
      <template v-slot:title>{{ $t("business.modal.amend_title") }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("business.modal.amend_description") }}
        </p>
        <app-form-textarea
          v-model="business.comment"
          :errorMessage="errors.comment"
        />
      </template>
      <template v-slot:action>
        <app-button
          @onClick="
            updateStatusBusiness(BUSINESS_STATUS.AMENDED, business.comment)
          "
          :showf70Icon="false"
          :primary="false"
          type="button"
          class="
            sm:ml-3 sm:w-auto
            bg-primary
            hover:bg-primary
            text-white
            outline-none
          "
        >
          {{ $t("business.modal.amend_button") }}
        </app-button>
      </template>
    </app-modal>

    <!-- Decline Business-->
    <app-modal :show="show_modal_decline" @close="show_modal_decline = false">
      <template v-slot:title>{{ $t("business.modal.decline_title") }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("business.modal.decline_description") }}
        </p>
        <app-form-textarea
          v-model="business.comment"
          :errorMessage="errors.comment"
        />
      </template>
      <template v-slot:action>
        <app-button
          @onClick="
            updateStatusBusiness(BUSINESS_STATUS.REJECTED, business.comment)
          "
          :showf70Icon="false"
          :primary="false"
          type="button"
          class="
            sm:ml-3 sm:w-auto
            bg-decline
            hover:bg-decline
            text-white
            outline-none
          "
        >
          {{ $t("business.modal.decline_button") }}
        </app-button>
      </template>
    </app-modal>

    <!-- Upgrade Business-->
    <app-modal :show="show_modal_upgrade" @close="show_modal_upgrade = false">
      <template v-slot:title>{{ $t("business.modal.upgrade_title") }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("business.modal.upgrade_description") }}
        </p>
      </template>
      <template v-slot:action>
        <app-button
          @click="upgradeBusiness()"
          :showf70Icon="false"
          type="button"
          class="sm:ml-3 sm:w-auto"
        >
          {{ $t("business.modal.upgrade_button") }}
        </app-button>
      </template>
    </app-modal>


    <!--Suspend business-->
    <app-modal :show="show_modal_suspend" @close="show_modal_suspend = false">
      <template v-slot:title>{{ $t("business.modal.suspend_title") }}</template>
      <template v-slot:body>
        <p class="text-sm text-gray-500">
          {{ $t("business.modal.suspend_description") }}
        </p>
        <app-form-textarea
          v-model="business.comment"
          :errorMessage="errors.comment"
        />
      </template>
      <template v-slot:action>
        <app-button
          @click="updateStatusBusiness(BUSINESS_STATUS.SUSPENDED, business.comment)"
          :showf70Icon="false"
          :primary="false"
          type="button"
          class="sm:ml-3 sm:w-auto
          bg-warning-badge
          hover:bg-yellow
          text-black
          outline-none"
        >
          {{ $t("business.modal.suspend_button") }}
        </app-button>
      </template>
    </app-modal>

    <div class="container mx-auto p-4">
      <div v-if="business.id">
        <div class="flex-1 h-full md:grid grid-cols-4 block">
          <div class="px-5 py-10">
            <div class="flex space-x-1 items-center mb-6">
              <app-icon
                name="ChevronLeftIcon"
                @click="this.$router.push({ name: 'business.index' })"
                class="w-7 cursor-pointer -ml-3"
              />
              <p class="text-lg font-semibold">
                {{ $t("business.info.details_menu") }}
              </p>
            </div>
            <app-button-border-left
              v-for="menu in menu_items"
              v-bind:key="menu.title"
              :active="isCurrentRouteName(menu.route_name)"
              @onMenuClick="onMenuClick(menu)"
              :show="isMenuShow(menu)"
              :disabled="isMenuDisabled(menu)"
            >
              <p class="text-left">{{ menu.title }}</p>
            </app-button-border-left>
          </div>
          <div class="col-span-3 md:px-6 py-10 px-5 space-y-2">
            <div class="mb-5 md:pl-10">
              <div
                class="
                  flex
                  justify-between
                  lg:flex-row
                  flex-col
                  lg:space-y-0
                  space-y-3
                  mb-3
                "
              >
                <div class="flex justify-between space-x-3 items-center">
                  <span class="lg:text-2xl text-xl font-medium">
                    {{ business.name ?? "-" }}
                    {{ $t("business.info.information") }}
                  </span>
                  <app-badge class="border border-gray-200 h-8">
                    {{ business.merchant_type?.split("_").join(" ") ?? "-" }}
                  </app-badge>
                </div>
                <div
                  class="
                    flex-1 flex
                    space-x-4
                    lg:justify-end
                    justify-between
                    place-content-center
                    items-center
                  "
                >
                  <app-badge class="px-4 py-2 ml-0 md:ml-3"
                    :status="getStatus(business.business_status_id ?? 0)"
                  >
                    {{ business.status?.name ?? "-" }}
                  </app-badge>
                  <app-button-dropdown>
                    <app-button-outline class="py-1">
                      {{ $t("general.actions") }}
                      <app-icon name="ChevronDownIcon" class="w-5 h-5 ml-2" />
                    </app-button-outline>

                    <template #menu-items>
                      <app-button-dropdown-item
                        v-for="item in create_menu_action_items"
                        :key="item.key"
                        :disabled="item.disable"
                        :show="item.show"
                        @onMenuClick="onMenuActionClick(item)"
                        class="p-3"
                      >
                        {{ item.title }}
                      </app-button-dropdown-item>
                    </template>
                  </app-button-dropdown>
                </div>
              </div>
              <div class="flex flex-col">
                <hr class="mt-3 mb-5" />
                <div
                  v-if="
                    business.business_status_id == BUSINESS_STATUS.REJECTED ||
                    business.business_status_id == BUSINESS_STATUS.AMENDED ||
                    business.business_status_id == BUSINESS_STATUS.SUSPENDED
                  "
                >
                  <app-container-comments />
                </div>
              </div>
              <router-view
                :menu_action="menu_action"
                :edit_mode="edit_mode"
                @update:edit_mode="edit_mode = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
//import MERCHANT_TYPE from "@/utils/const/merchant_type";

export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
     // MERCHANT_TYPE: MERCHANT_TYPE,
      show_modal_approve: false,
      show_modal_amend: false,
      show_modal_decline: false,
      show_modal_upgrade: false,
      show_modal_suspend: false,
      comment: null,
      menu_action: null,
      edit_mode: true,
      submitLoading: false,
      errors: {
        comment: [],
      },
      menu_items: [
        {
          id: 1,
          title: "Business structure",
          route_name: "business.index.structure",
        },
        {
          id: 2,
          title: "Business details",
          route_name: "business.index.detail",
        },
        {
          id: 3,
          title: "Business representative",
          route_name: "business.index.representative",
        },
        {
          id: 4,
          title: "Business owners",
          route_name: "business.index.owners",
        },
        {
          id: 5,
          title: "Support details",
          route_name: "business.index.support-details",
        },
        {
          id: 6,
          title: "Bank details",
          route_name: "business.index.bank-details",
        },
        {
          id: 7,
          title: "Supporting Documents",
          route_name: "business.index.support-documents",
        },
        {
          id: 8,
          title: "",
          route_name: "",
        },
        {
          id: 9,
          title: "Payment Provider",
          route_name: "business.index.payment-provider",
        },
      ],
    };
  },

  computed: {
    business() {
      return this.$store.getters["businessStore/business"];
    },
    loading() {
      return this.$store.getters["businessStore/loading"];
    },
    create_menu_action_items() {
      return [
        {
          key: "edit",
          title: this.edit_mode
            ? this.$t("business.info.edit_information")
            : this.$t("business.info.cancel_edit"),
          disable: false,
          show: true,
        },
        {
          key: "approve",
          title: this.$t("business.info.approve"),
          disable: false,
          show: true,
        },
        { 
          key: "amend", 
          title: this.$t("business.info.amend"), 
          disable: false,
          show: true,
        },
        {
          key: "decline",
          title: this.$t("business.info.decline"),
          disable: false,
          show: true,
        },
        {
          key: "suspend",
          title: this.$t("business.info.suspend"),
          disable: false,
          show: true,
        },
        // hide for temporary
        // refer to https://sprints.zoho.com/team/swipe#itemdetails/P6/I23
        //   key: "upgrade",
        //   title: this.$t("business.info.upgrade"),
        //   disable: this.business.master_business_type ? true : false,
        //   show: this.isBusinessApproved(),
        // },
      ];
    },
  },

  beforeCreate() {
    this.$store.dispatch(
      "businessStore/getBusinessById",
      this.$route.params.business_id
    );
    this.$store.dispatch(
      "businessStore/getBusinessSupportDocuments",
      this.$route.params.business_id
    );
  },

  methods: {
    onMenuClick(menu) {
      this.$router.push({ name: menu.route_name });
    },

    onMenuActionClick(menu) {
      this.menu_action = menu.key;
      this.errors.comment = [];

      if (menu.key == "edit") {
        this.edit_mode = !this.edit_mode;
      } else if (menu.key == "approve") {
        this.show_modal_approve = true;
      } else if (menu.key == "amend") {
        this.show_modal_amend = true;
      } else if (menu.key == "decline") {
        this.show_modal_decline = true;
      }  else if (menu.key == "suspend") {
        this.show_modal_suspend = true;
      }
    },

    async updateStatusBusiness(businessStatusId, comment) {
      if (
        businessStatusId == BUSINESS_STATUS.AMENDED ||
        businessStatusId == BUSINESS_STATUS.REJECTED ||
        businessStatusId == BUSINESS_STATUS.SUSPENDED
      ) {
        if (comment == null || comment == "") {
          this.errors.comment = [
            this.$t("general.field_required", {
              name: "reason",
            }),
          ];
          return;
        }
      }

      this.submitLoading = true;
      this.show_modal_amend = false;
      this.show_modal_decline = false;
      this.show_modal_suspend = false;

      const businessResult = await this.$store.dispatch(
        "businessStore/statusBusiness",
        {
          id: this.$route.params.business_id,
          business_status_id: businessStatusId,
          comment: comment,
        }
      );

      if (businessResult != null) {
        this.comment = null;
        this.hasChanged = false;
        this.submitLoading = false;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },

    async upgradeBusiness() {
      this.submitLoading = true;
      this.show_modal_upgrade = false;

      const businessResult = await this.$store.dispatch(
        "businessStore/upgradeBusiness",
        this.business
      );

      if (businessResult != null) {
        this.submitLoading = false;
        this.$notify(
          {
            group: "success",
            title: this.$t("business.main.upgrade_success"),
          },
          3000
        );
      } else {
        this.submitLoading = false;
        this.$notify(
          {
            group: "error",
            title: this.$t("business.main.upgrade_failed"),
          },
          3000
        );
      }
    },

    isCurrentRouteName(route_name) {
      return this.$route.name == route_name;
    },

    isBusinessCreated() {
      return this.business != null ? this.business.id != null : false;
    },

    isBusinessDetailsCreated() {
      return this.business.detail != null
        ? this.business.detail.id != null
        : false;
    },

    isBusinessRepresentativeCreated() {
      return this.business.representative != null
        ? this.business.representative.id != null
        : false;
    },

    isBusinessOwnersCreated() {
      return this.business.owners != null
        ? this.business.owners.length > 0
        : false;
    },

    isBusinessSupportDetailsCreated() {
      return this.business.support_detail != null
        ? this.business.support_detail.id != null
        : false;
    },

    isBusinessBanksCreated() {
      return this.business.bank != null ? this.business.bank.id != null : false;
    },

    isBusinessApproved() {
      return this.business.business_status_id == BUSINESS_STATUS.APPROVED 
      //&& this.business.merchant_type != MERCHANT_TYPE.SUB_MERCHANT
        ? true
        : false;
    },

    getStatus(status_id) {
      switch (status_id) {
        case 1:
          return "light";
        case 2:
          return "light";
        case 3:
          return "success";
        case 4:
          return "error";
        case 5:
          return "warning";
          case 6:
          return "warning";
        default:
          return "light";
      }
    },

    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },

    isMenuShow(menu) {
      switch (menu.id) {
        case 8:
        case 9:
          return this.business?.business_status_id == BUSINESS_STATUS.APPROVED;
        default:
          return true;
      }
    },

    isMenuDisabled(menu) {
      switch (menu.id) {
        case 8:
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

